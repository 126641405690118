<template>
	<div class="body">
		<div class="search">
			<Form :model="SearchForm" inline @submit.native.prevent>
				<FormItem>
					<Input placeholder="请输入模板名称" v-model="SearchForm.name" type="text" search enter-button @on-search="init"></Input>
				</FormItem>
				<FormItem :label-width="10"><Button type="primary" icon="md-add" @click="openModal">添加模版</Button></FormItem>
				<FormItem :label-width="0"><Button type="primary" icon="md-refresh" @click="getSmsTemplateStatus">更新短信模板状态</Button></FormItem>
			</Form>
		</div>
		<div class="table-item">
			<Table :data="data" :columns="columns" :loading="table_loading">
				<template slot-scope="{row}" slot="operation">
					<a @click="temp_edit(row.id)" style="margin-right:10px;">编辑</a>
					<a @click="temp_del(row.id)">删除</a>
				</template>
			</Table>
			<Page class-name="page-r" :total="SearchForm.total" :current="SearchForm.page" :page-size="SearchForm.pageSize"
			:page-size-opts="[15,30,50]" show-total show-sizer @on-change="set_current" @on-page-size-change="set_per"></Page>
		</div>
		<Modal v-model="modal" :title="is_modify?'修改短信模板':'添加短信模板'">
			<Form :model="RuleForm" label-colon label-position="right" :label-width="100" :rules="rules" ref="ruleform">
				<FormItem label="模板名称" prop="TemplateName">
					<Input type="text" v-model="RuleForm.TemplateName"></Input>
				</FormItem>
				<FormItem label="模板类型">
					<RadioGroup v-model="RuleForm.SmsType">
						<Radio :label="0">普通短信</Radio>
						<Radio :label="1">营销短信</Radio>
					</RadioGroup>、
				</FormItem>
				<FormItem label="模板内容" prop="TemplateContent">
					<Input type="textarea" :rows="4" v-model="RuleForm.TemplateContent" placeholder="模板内容:内容为通过手机号发送给用户的内容;如果是验证码需要写{1}、{2}这个代表验证码与多少分钟内,示例:您的验证码为{1},{2}分钟内有效"></Input>
				</FormItem>
				<FormItem label="模板说明" prop="Remark">
					<Input type="textarea" v-model="RuleForm.Remark"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="addTemplate">确定</Button>
				<Button @click="modal=false">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				name: "SmsTemp",
				table_loading: false,
				modal: false,
				is_modify: 0,
				RuleForm: {
					Version:'2021-01-11',
					TemplateName: '',
					TemplateContent: '',
					SmsType:0,
					Remark: '',
					International: 0,
					UseType: 0	//用途
				},
				rules: {
					TemplateName: [{
						required: true,
						message: '请输入模板名称',
						trigger: 'blur'
					}],
					TemplateContent: [{
						required: true,
						message: '请输入模板内容',
						trigger: 'blur'
					}],
					Remark: [{
						required: true,
						message: '请输入申请说明',
						trigger: 'blur'
					}],
				},
				SearchForm: {
					name: null,
					total: 0,
					page: 1,
					pageSize: 15
				},
				columns: [
					{title: 'ID', key: 'id', width: 60},
					{title: '模板名称', key: 'name', minWidth: 100},
					{title: '模板内容', key: 'content', minWidth: 200},
					{title: '模板编号', key: 'template_id', minWidth: 110},
					{title: '申请说明', key: 'remarks', minWidth: 150},
					{title: '模板类型', key: 'type', minWidth: 100,render:(h,params)=>{
						return h('div',params.row.type?'营销短信':'普通短信')}},
					{title: '审核状态', key: 'status', width: 100,render:(h,params)=>{
						return h('Tag',{
							props:{
								color:params.row.status==0?'success':(params.row.status==1?'warning':'error')
							}
						},params.row.status==0?'审核通过':(params.row.status==1?'审核中':'审核未通过'))}},
					{title: '操作', slot: 'operation', align: 'center', minWidth: 200}
				],
				data: [],
			}
		},
		created() {
			this.init();
			this.getSmsTemplateStatus();
		},
		methods: {
			init() {
				var _this = this;
				this.table_loading = true;
				this.requestApi('/adm/get_template_list',{data:this.SearchForm}).then(
					(res) => {
						_this.table_loading = false;
						if (res.status){
							_this.data = res.data.data;
							_this.SearchForm.total = res.data.total;
						}
					}
				)
			},
			//编辑短信模板
			temp_edit(id) {
				var _this = this;
				this.requestApi('/adm/get_find_template',{id:id}).then(function (res) {
					if(res.status){
						_this.RuleForm.TemplateName = res.data.name;
						_this.RuleForm.TemplateId = res.data.template_id;
						_this.RuleForm.TemplateContent = res.data.content;
						_this.RuleForm.SmsType = res.data.type;
						_this.RuleForm.Remark = res.data.remarks;
						_this.RuleForm.International = res.data.International;
						_this.modal = true;
						_this.is_modify = 1;
					}else {
						_this.alertErr(res.msg)
					}
				})
			},
			//删除短信模板
			temp_del(id) {
				var _this = this;
				this.$Modal.confirm({
					title: '提示',
					content: '确定删除该短信模板吗？',
					onOk: () => {
						this.requestApi('/adm/del_sms_template',{id:id}).then((res) => {
								if(res.status){
									_this.alertSucc(res.msg)
									_this.init()
								}else{
									_this.alertErr(res.msg);
								}
							}
						)
					}
				})
			},
			//添加短信模版
			addTemplate() {
				var _this = this;
				this.$refs['ruleform'].validate(valid => {
					if (valid) {
						_this.requestApi('/adm/add_sms_template',{type:this.is_modify,data:this.RuleForm}).then(
							(res) => {
								if (res.status){
									_this.alertSucc(res.msg);
									_this.modal = false;
									_this.init();
								}else{
									_this.alertErr(res.msg)
								}
							}
						)
					}
				})
			},
			getSmsTemplateStatus(){
				var _this = this;
				this.requestApi('/adm/get_sms_template_status',{data:this.SearchForm}).then(function(res){
					if(res.status) _this.init();
				})
			},
			openModal(){
				this.is_modify = 0;
				this.RuleForm  = {
					Version:'2021-01-11',
					TemplateName: '',
					TemplateContent: '',
					SmsType:0,
					Remark: '',
					International: 0,
					UseType: 0
				}
				this.$refs.ruleform.resetFields();
				this.modal = true;
			},
			//切换页数
			set_current(page) {
				this.SearchForm.page = page;
				this.init()
			},
			//切换条数
			set_per(pageSize) {
				this.SearchForm.page = 1;
				this.SearchForm.pageSize = pageSize;
				this.init()
			}
		}
	}
</script>

<style scoped>
	.body {
		padding: 16px;
		background-color: #fff;
	}

	.search {
		background-color: rgb(248, 248, 249);
		padding: 5px;
		margin-bottom: 10px;
	}

	.page-r {
		margin-top: 10px;
		text-align: right;
	}
</style>
